<template>
  <div style="min-height: 2000px;">
    <AppFixedPageTitle title="Requisições de Cotação" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter">
        </AppSearchBar>
      </template>
    
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click.prevent="$router.push('/purchase/request/create')"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
            />
          <AppPageHeaderActionsButton
            @click="handleShowModal({ ref: 'modalPurchaseCategory', data: entityBudget })"
            text="Cadastros"
            type="warning"
            icon="/img/icons/product-documents.png"
            />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"

    />
    <div class="container-fluid">
      <!-- CARDS -->
      <Cards v-if="listType === 'cards'" :requests="$_purchaseRequests" @fetch="listItems" />
      <!-- TRIGGER (Ativa paginação ao chegar no final da lista) -->
      <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)"/>
      <!-- TABELA -->
      <Table v-if="listType === 'table' && $_purchaseRequests.length > 0"
        :requests="$_purchaseRequests" @fetch="listItems" />
      <!-- <LoadingPagination :show="loadingPagination"/> -->
      <div class="d-flex" v-if="isLoading">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" />
      </div>
      <PuzlEmptyData v-if="!isLoading && $_purchaseRequests.length <= 0" />
    </div>

    <ModalPurchaseCategory ref="modalPurchaseCategory" />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PurchaseRequestEnum } from "@/enum/PurchaseRequestEnum";
import BackButton from '@/components/Utils/BackButton';
import MultiFilter from "@/components/Utils/MultiFilterV3";
import Table from './Shared/_Table';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Cards from './Shared/_Cards'
import ModalPurchaseCategory from './Shared/_ModalPurchaseCategory'
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import {
  AppSearchBar,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppViewTrigger,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppTabSelect,

} from "../../../../../components/AppGlobal";
import { PurchaseRequestListFilterType, initPurchaseRequestListFilterType } from "./types";
import { date } from "../../../../../helpers";

export default {
  name: "PurchasesRequestIndex",
  mixins: [cursorPaginate],
  components: {
    Cards,
    Table,
    BackButton,
    PuzlSelect,
    MultiFilter,
    AppSearchBar,
    AppTabSelect,
    PuzlEmptyData,
    AppPageHeader,
    cursorPaginate,
    InputDatePicker,
    SkeletonPuzlGrid,
    LoadingPagination,
    AppFixedPageTitle,
    BaseButtonHoverable,
    AppPageHeaderActions,
    ModalPurchaseCategory,
    AppPageHeaderActionsButton,
    AppViewTrigger,
  },
  data() {
    return {
      purchaseRequestEnum: PurchaseRequestEnum,
      isLoading: false,
      listType: 'cards',
      filter: initPurchaseRequestListFilterType(),
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      statusButtons: [
        {
          text: 'pendentes', count: 0, color: '#F2B532', border: '0.5px solid #F2B532',
          status: 0, icon: '/img/icons/icons8/ios/hourglass_warning.png'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      $_purchaseRequests: "purchaseRequest/fetch",
      $_users: "user/getAllActiveUsersSimplified",
      $_short_plants: "plant/activeShortItems",
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.in_status.length === 0,
        },
        {
          id: PurchaseRequestEnum.PENDING, 
          name: 'Pendentes', 
          selected: this.filter.in_status.includes(PurchaseRequestEnum.PENDING),
        },
        {
          id: PurchaseRequestEnum.APPROVED, 
          name: 'Aprovados', 
          selected: this.filter.in_status.includes(PurchaseRequestEnum.APPROVED),
        },
        {
          id: PurchaseRequestEnum.DENIED, 
          name: 'Negados', 
          selected: this.filter.in_status.includes(PurchaseRequestEnum.DENIED),
        }
      ];
    },
  },
  methods: {
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
     listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }
      this.prepareFilter();
      this.$store
        .dispatch("purchaseRequest/fetchItemsPaginate", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => this.isLoading = false);
    },
    /**
     * Padrão do filtro principal
     * @returns {PurchaseRequestListFilterType}
     */
    /**
     * Preparar filtro antes da listagem
     */
     prepareFilter() {
      this.filter.input_company_plant_id = this.searchBarFilter.company_plant.selected;
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.deadline_range = this.searchBarFilter.range;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        company_plant: {
          items: this.$_short_plants,
        },
        range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {PurchaseRequestListFilterType}
     */
    defaultFilter() {
      return {
        ...initPurchaseRequestListFilterType(),
        deadline_range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.in_status.length === 1 && this.filter.in_status.includes(item.id);
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.in_status = item.id === null ? [] : [item.id];
      this.listItems();
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
  },
  async mounted() {
    this.$store.dispatch("user/getAllActiveUsersSimplified");
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
    }
    this.clearFilter();
  },
};
</script>
<style scoped>
.status-button {
  border-radius: 8px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 10rem;
  margin-right: 1rem;
  background-color: #ffff;
  font-weight: 500;
}

.header-button .base-button {
  width: 200px !important;
  height: 6px;
}

#customButton button {
  color: #4b4b50;
  border: 1px solid #DCDFE6;
  background-color: white;
}

#customButton .btn-active {
  color: white !important;
}

.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
